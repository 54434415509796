<template>
  <div class="cont">
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>PMS</el-breadcrumb-item>
           <el-breadcrumb-item>价格管理</el-breadcrumb-item>
           <el-breadcrumb-item to="/house_price_strategy">房价策略</el-breadcrumb-item>
           <el-breadcrumb-item>{{addEdit}}房价策略</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <el-button class="bg-gradient" type="primary"  @click="goBack">返回</el-button> -->
     </el-row>
     <el-row class="content-box">
      <h2 style="border-bottom: 1px solid;color: #606266;">{{ addEdit }}房价策略</h2>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="addHPSform" label-width="110px">
      <ul>
        <li>
          <el-form-item label="策略名称" prop="name"  style="margin-top: 22px;">
            <el-input v-model="ruleForm.name" class="width-350  m-right-6"  :disabled="bool"></el-input>
          </el-form-item>
        </li>
        <li>
          <div>条件配置：</div>
          <el-form-item label="房型选择：" prop="roomTypeIds">
            <el-select v-model="ruleForm.roomTypeIds" class="width-350  m-right-6 noborder" multiple  :disabled="bool">
              <el-option
                  v-for="(item,index) in froollist"
                  :key="index"
                  :label="item.roomTypeName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单来源：" prop="orderSource">
            <el-select v-model="ruleForm.orderSourceIds" class="width-350  m-right-6 noborder" multiple value-key="id" :disabled="bool" clearable>
              <el-option
                  v-for="item in ordersourceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.dictKey">
              </el-option>
            </el-select>
          </el-form-item>
        </li>
        <li>
          <div>策略内容：</div>
          <el-form-item class='constituteBox' label='门市房价:' prop="changeAmount" >
            <el-select v-model="ruleForm.changeType" size='mini' :disabled="bool">
              <el-option v-for="item in changeTypeList" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
            <el-input v-model="ruleForm.changeAmount" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" placeholder="写具体变动的额度"
                      size='mini' type="text" :disabled="bool">
              <template v-if="this.ruleForm.changeType == 'DISCOUNT'" slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item class='constituteBox' label='会员房价:'  prop="memberChangeAmount">
            <el-select v-model="ruleForm.memberChangeType" size='small' :disabled="bool">
              <el-option v-for="item in changeTypeList" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
            <el-input v-model="ruleForm.memberChangeAmount" onkeyup="this.value=this.value.replace(/[^\d.]/g,'')" placeholder="写具体变动的额度"
                      size='small' type="text" :disabled="bool">
              <template v-if="this.ruleForm.memberChangeType == 'DISCOUNT' " slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-row>
            <el-form-item label="生效时间" required>
              <el-date-picker
                  v-model="activityTime"
                  :picker-options="pickerOptions"
                  class="width-220 m-right-10"
                  end-placeholder="结束日期"
                  range-separator="至"

                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  @change="getvalidity()"
                  :disabled="bool">
              </el-date-picker>
            </el-form-item>
          </el-row>

          <el-form-item v-show="ruleForm.beginTime !==  '' ? ruleForm.endTime !== '' ? true : false :false " label="">
            实施时间内的：
            <el-checkbox-group v-model="checkWeek" style="text-align: left;display: inline-block"  :disabled="bool">
              <el-checkbox v-for="item in weekList" :key="item" :label="item" @change="getcheckbox(item)">{{ item }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </li>
      </ul>

      <el-form-item>
        <el-col :span="22">
          <el-button @click="goBack">返回</el-button>
          <el-button class="bg-gradient" size="medium" type="primary" @click="submitForm('ruleForm')" :disabled="bool">保存</el-button>
        </el-col>
      </el-form-item>
    </el-form>
     </el-row>
  </div>
</template>


<script>
import { mapState } from "vuex";
import { urlObj   } from '@/api/interface'
import {  pms } from '@/api/interface/pms'
export default {
  data() {
    return {
      hotelId: '',
      addEdit: '',
      activityTime: [],
      checkWeek: [],
      pickerOptions: {
        disabledDate(time) {
          //如果没有后面的-8.64e7就是不可以选择今天的
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        },
      },
      ruleForm: {
        name: '',                 //策略名称
        // customerChoice:"全部", //客户选择
        roomTypeIds: [],          //房型选择
        orderSourceIds: [],       //订单来源
        changeType: 'ADD',
        changeAmount: '',             //门市房价额度变动
        memberChangeType: 'ADD',
        hotelId: '',
        memberChangeAmount: '',   //会员房价额度变动
        beginTime: '',           //生效开始时间
        endTime: '',             //生效结束时间
        id: '',
        state: 'VALID',
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
      },
      rules: {
        name: [{ required: true, message: '请输入策略名称', trigger: 'blur'},],
        // customerChoice: [{ required: true, message: '请选择客户选择', trigger: 'change' },],
        changeAmount: [{ required: true, message: '请输入门市房价变动额度', trigger: 'blur'}],
        memberChangeAmount: [{ required: true, message: '请输入会员房价变动额度', trigger: 'blur'}],
        beginTime: [{type: 'date', required: true, message: '请选择生效时间', trigger: 'change'}],
        endTime: [{type: 'date', required: true, message: '请选择生效时间', trigger: 'change'}],
      },
      froollist: [],
      ordersourceList: [],
      emitinfo: '',
      changeTypeList: [{
        value: 'ADD',
        label: '房价增加',
      }, {
        value: 'REDUCE',
        label: '房价减少',
      }, {
        value: 'DISCOUNT',
        label: '房价折扣',
      }],
      weekList: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"],
       bool:false,
    }
  },
   computed: {
      ...mapState(["hotelInfo"])
   },
  mounted() {

    if (this.$route.query.roomAddEdit == 'edit' || this.$route.query.roomAddEdit == 'copy' || this.$route.query.roomAddEdit == 'check') {
      this.emitinfo = JSON.parse(sessionStorage.getItem('strategyinfo'))
      //type 1 房型
      //type 2 订单
      let roomtypelist = this.emitinfo.strategyRelationList.filter(item => {
        return item.type == 1
      })
      let strategyId = this.emitinfo.strategyRelationList.filter(item => {
        return item.type == 2
      })
      roomtypelist.forEach(item => {
        this.ruleForm.roomTypeIds.push(item.typeId)
      })
      strategyId.forEach(item => {
         if(item.pmsSource){
            this.ruleForm.orderSourceIds.push(item.pmsSource)
         }
      })
      this.hotelId = this.emitinfo.hotelId,
       this.ruleForm.name = this.emitinfo.name,
       this.ruleForm.changeType = this.emitinfo.changeType
      this.ruleForm.memberChangeType = this.emitinfo.memberChangeType
      this.ruleForm.memberChangeAmount = this.emitinfo.memberChangeAmount
      this.ruleForm.changeAmount = this.emitinfo.changeAmount
      this.activityTime = [this.emitinfo.beginTime, this.emitinfo.endTime]
      this.ruleForm.beginTime = this.emitinfo.beginTime
      this.ruleForm.endTime = this.emitinfo.endTime
      this.ruleForm.mon = this.emitinfo.mon
      this.ruleForm.tue = this.emitinfo.tue
      this.ruleForm.wed = this.emitinfo.wed
      this.ruleForm.thu = this.emitinfo.thu
      this.ruleForm.fri = this.emitinfo.fri
      this.ruleForm.sat = this.emitinfo.sat
      this.ruleForm.sun = this.emitinfo.sun
      if(this.$route.query.roomAddEdit == 'edit'){
        this.ruleForm.id = this.emitinfo.id
      }else if(this.$route.query.roomAddEdit == 'check'){
         // this.ruleForm.id = this.emitinfo.id
         this.bool = true;
       }else {
        this.ruleForm.id = ''
      }
      this.getcheckboxdata()
    }
    this.ruleForm.hotelId = this.hotelInfo.id
    switch (this.$route.query.roomAddEdit){
      case "add" :
        this.addEdit = "新增"
      break;
      case "copy" :
        this.addEdit = "复制"
        break;
      case "edit" :
        this.addEdit = "编辑"
        break;
       case "check" :
          this.addEdit = "查看"
       break;
    }
    this.getfroollist()
    this.getPriceChannel()
  },
  beforeDestroy() {
    sessionStorage.removeItem('strategyinfo')
  },
  methods: {
//获取房型列表
    getfroollist() {
      const url = pms.pmsRoomTypes
      const param = {
        hotelId: this.ruleForm.hotelId,
        roomTypeName: this.roomTypeName
      }
      this.$axios.post(url, param, 'json').then(res => {
        if (res.success) {
          this.froollist = res.records
        }
      })
    },

    //复选框回显
    getcheckboxdata() {
      if (this.ruleForm.mon == 1) {
        this.checkWeek.push('星期一')
      }
      if (this.ruleForm.tue == 1) {
        this.checkWeek.push('星期二')
      }
      if (this.ruleForm.wed == 1) {
        this.checkWeek.push('星期三')
      }
      if (this.ruleForm.thu == 1) {
        this.checkWeek.push('星期四')
      }
      if (this.ruleForm.fri == 1) {
        this.checkWeek.push('星期五')
      }
      if (this.ruleForm.sat == 1) {
        this.checkWeek.push('星期六')
      }
      if (this.ruleForm.sun == 1) {
        this.checkWeek.push('星期日')
      }
    },
    //获取复选框
    getcheckbox() {
      if (this.checkWeek.indexOf('星期一') != -1) {
        this.ruleForm.mon = 1
      } else {
        this.ruleForm.mon = 0
      }
      if (this.checkWeek.indexOf('星期二') != -1) {
        this.ruleForm.tue = 1
      } else {
        this.ruleForm.tue = 0
      }
      if (this.checkWeek.indexOf('星期三') != -1) {
        this.ruleForm.wed = 1
      } else {
        this.ruleForm.wed = 0
      }
      if (this.checkWeek.indexOf('星期四') != -1) {
        this.ruleForm.thu = 1
      } else {
        this.ruleForm.thu = 0
      }
      if (this.checkWeek.indexOf('星期五') != -1) {
        this.ruleForm.fri = 1
      } else {
        this.ruleForm.fri = 0
      }
      if (this.checkWeek.indexOf('星期六') != -1) {
        this.ruleForm.sat = 1
      } else {
        this.ruleForm.sat = 0
      }
      if (this.checkWeek.indexOf('星期日') != -1) {
        this.ruleForm.sun = 1
      } else {
        this.ruleForm.sun = 0
      }
    },
    //获取订单来源列表
    getPriceChannel() { // 获取价格渠道
      const url = pms.getorderSource
      const param = {
        hotelId: this.hotelInfo.id,
        roomTypeName: this.roomTypeName
      }
      this.$axios.get(url, param).then(res => {
        if (res.success) {
          this.ordersourceList = res.records['order-source']
        }
      })
    },
    //获取有效期
    getvalidity() {
      this.ruleForm.beginTime = this.activityTime[0]
      this.ruleForm.endTime = this.activityTime[1]
    },
    //返回
    goBack() {
      this.$router.go(-1)
    },
    //保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.changeType == 'DISCOUNT' && this.ruleForm.changeAmount > 100 || this.ruleForm.changeAmount < 0) {
            this.$message.error('折扣率只能在0~100')
          } else if (this.ruleForm.memberChangeType == 'DISCOUNT' && this.ruleForm.memberChangeAmount > 100 || this.ruleForm.memberChangeAmount < 0) {
            this.$message.error('折扣率只能在0~100')
          } else if (this.checkWeek == '') {
            this.$message.error('请选择每周生效日期')
          } else {
            this.ruleForm.roomTypeIds = this.ruleForm.roomTypeIds.join(",")
            this.ruleForm.orderSourceIds = this.ruleForm.orderSourceIds.join(",")
            const param = this.ruleForm
            const url = pms.getAHPStrategy
            this.$axios.post(url, param).then((res) => {
              if (res.success) {
                this.$message({
                  message: this.addEdit+'成功',
                  type: 'success'
                });
                history.go(-1)
              }
            })
          }
        } else {
          this.$message('请完善信息');
          return false;
        }
      });
    },
  },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               if (this.$route.query.roomAddEdit == 'edit' || this.$route.query.roomAddEdit == 'copy' || this.$route.query.roomAddEdit == 'check') {
                  this.getcheckboxdata()
               }
               this.getfroollist()
               this.getPriceChannel()
            }
         },
         deep: true
      }
   }
}

</script>


<style lang="scss">

.addHPSform {
  ul {
    list-style: none;
    li {
      border-bottom: 1px dashed #d7d7d7;
    }
    li:last-child {
      border-bottom: 0px dashed #d7d7d7;
    }
    li > div:first-child {
      margin: 20px 0px;
    }
  }
}
.constituteBox {
  .el-form-item__content {
    display: flex;
    justify-content: space-around;
    border: 1px solid #C0C4CC;
    border-radius: 4px;
    width: 350px;
    margin-top: 6px;
     height: 38px;
    .el-input--mini {
      height: 38px;
      line-height: 38px
    }
    .el-input__inner {
      border: none;
       height: 36px;
       line-height: 36px
    }
  }
}
.el-form-item__content div {
  text-align: right;
}

.el-form-item__content{
   line-height: 0px;
}
</style>
